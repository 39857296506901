<template>
  <b-overlay
    :show="submitting"
    spinner-variant="primary"
    spinner-type="grow"
    :variant="darkMode?'dark':'white'"
    spinner-small
    rounded="md"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0 justify-content-center">
        <div
          class="col-12 col-lg-6 col-xl-6 d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <div
            class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto"
          >
            <p class="text-center">
              <auth-logo />
            </p>
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1 text-center"
            >
              {{ $t('welcome_to') }} Lenon
            </b-card-title>
            <b-card-text class="mb-2 text-center">
              {{ $t('register_note') }}
            </b-card-text>

            <error-display :error="error" />
            <!-- form -->
            <validation-observer
              ref="registerForm"
              v-slot="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="register()"
              >
                <app-collapse
                  accordion
                  type="margin"
                >
                  <app-collapse-item
                    id="school-info-collapse"
                    title="School Information"
                  >
                    <lenon-input
                      v-model="school.name"
                      name="school_name"
                      rules="required|max_length:150"
                      placeholder="school_name_ph"
                      label="School Name"
                    />
                    <lenon-input
                      v-model="school.alias"
                      label="Alias(<b>We will use this as your sms sender address</b>)"
                      name="alias"
                      rules="required|max_length:11"
                      placeholder="alias_ph"
                    />
                    <div class="row">
                      <div class="col-lg-6">
                        <lenon-input
                          v-model="school.school_phone"
                          name="school_phone"
                          rules="required|max_length:20"
                          label="Phone"
                        />
                      </div>
                      <div class="col-lg-6">

                        <lenon-input
                          v-model="school.school_email"
                          name="school_email"
                          rules="required|email"
                          label="Email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <!--                                        <div class="row mt-2">-->
                    <!--                                            <div :class="documentPreview!==null?'col-10':'col'">-->
                    <!--                                                <lenon-file-button-->
                    <!--                                                        ref="docUploader"-->
                    <!--                                                        name="verification_doc"-->
                    <!--                                                        class="full-width"-->
                    <!--                                                        variant="outline-primary"-->
                    <!--                                                        label="Verification Document"-->
                    <!--                                                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,-->
                    <!--text/plain, application/pdf, image/*"-->
                    <!--                                                        @onFileUpload="handleUpload"-->
                    <!--                                                />-->
                    <!--                                            </div>-->
                    <!--                                            <div-->
                    <!--                                                    v-if="documentPreview!==null"-->
                    <!--                                                    class="col-2"-->
                    <!--                                            >-->
                    <!--                                                <b-avatar-->
                    <!--                                                        size="45px"-->
                    <!--                                                        variant="light-primary"-->
                    <!--                                                >-->
                    <!--                                                    <feather-icon icon="PaperclipIcon"/>-->
                    <!--                                                </b-avatar>-->
                    <!--                                            </div>-->
                    <!--                                        </div>-->
                    <!--                                        <small>-->
                    <!--                                            <b>Upload any document at all that proves you represent said school (eg.-->
                    <!--                                                Utility Bill, Receipts, etc).</b>-->
                    <!--                                            <b-->
                    <!--                                                    class="text-danger"-->
                    <!--                                                    style="font-size: 15px"-->
                    <!--                                            >*</b>-->
                    <!--                                        </small>-->
                  </app-collapse-item>
                  <app-collapse-item
                    id="school-address"
                    title="School Address"
                  >
                    <div class="row">
                      <div class="col-lg-6">
                        <lenon-select
                          v-model="address.country_id"
                          name="country"
                          label="Country"
                          rules="required"
                          label-name="name"
                          value-name="id"
                          :options="countries"
                        />

                      </div>
                      <div class="col-lg-6">
                        <lenon-select
                          v-model="address.region_id"
                          name="region"
                          label="Region"
                          rules="required"
                          label-name="name"
                          value-name="id"
                          :options="filteredRegions"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <lenon-select
                          v-model="address.city_id"
                          name="city"
                          label="City (Select Closest)"
                          rules="required"
                          label-name="name"
                          value-name="id"
                          :options="filteredCities"
                        />

                      </div>
                      <div class="col-lg-6">
                        <lenon-input
                          v-model="address.town"
                          name="town"
                          label="Town"
                          placeholder="Town"
                          rules="required|max_length:100"
                        />
                      </div>
                    </div>
                    <lenon-input
                      v-model="address.box_address"
                      name="box_address"
                      label="Box Address"
                      placeholder="Box Address"
                      rules="max_length:100"
                    />
                    <div class="row">
                      <div class="col">
                        <small class="text-danger"><b>We will verify school address before
                          account is approved.</b></small>
                      </div>
                    </div>
                  </app-collapse-item>
                  <app-collapse-item
                    id="admin-info"
                    title="School Administrator"
                  >
                    <div class="row">
                      <div class="col-lg-6">
                        <lenon-input
                          v-model="admin.first_name"
                          name="first_name"
                          rules="required|max_length:50"
                          label="First Name"
                          placeholder="First Name"
                        />

                      </div>
                      <div class="col-lg-6">
                        <lenon-input
                          v-model="admin.last_name"
                          name="last_name"
                          rules="required|max_length:50"
                          label="Last Name"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <lenon-input
                          v-model="admin.admin_phone"
                          name="phone"
                          rules="required|max_length:20"
                          label="Phone"
                        />
                      </div>
                      <div class="col-lg-6">

                        <lenon-input
                          v-model="admin.admin_email"
                          name="email"
                          rules="required|email"
                          label="Email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <lenon-input
                          v-model="admin.password"
                          name="password"
                          rules="required"
                          type="password"
                          label="Password"
                          suffix-icon="LockIcon"
                        />
                      </div>
                      <div class="col-lg-6">

                        <lenon-input
                          v-model="admin.password_confirmation"
                          name="confirm_password"
                          rules="required|confirmed:password"
                          type="password"
                          label="Confirm"
                          placeholder="Confirm Password"
                          suffix-icon="LockIcon"
                        />
                      </div>
                    </div>
                  </app-collapse-item>
                </app-collapse>
                <div class="mt-1 d-flex justify-content-between align-items-start">
                  <lenon-button
                    variant="outline-warning"
                    label="Login"
                    icon="ArrowLeftCircleIcon"
                    @onClick="()=>{$router.push({name: 'login'})}"
                  />
                  <lenon-button
                    name="submit-button"
                    class="float-right"
                    label="register"
                    :disabled="invalid"
                    type="submit"
                  />
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
        <!-- /Login-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCardText,
  BCardTitle,
  BForm,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
// import camelToSnakeCase from '@/utils/camelToSnakeCase'
import showToast from '@/lenon/mixins/showToast'
import { REGISTER_M, REGISTER_SIGNUP_ATTEMPT_M } from '@/graphql/mutations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { FETCH_ADDRESSES_Q } from '@/graphql/queries'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import AuthLogo from '@/lenon/components/AuthLogo.vue'

const { skin } = useAppConfig()
export default {
  components: {
    AuthLogo,
    ErrorDisplay,
    LenonSelect,
    LenonButton,
    LenonInput,
    BRow,
    BCardText,
    BCardTitle,
    BForm,
    ValidationObserver,
    BOverlay,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast],
  data() {
    return {
      error: {},
      submitting: false,
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      admin: {
        first_name: '',
        last_name: '',
        admin_email: '',
        admin_phone: '',
        password: '',
        password_confirmation: '',
      },
      school: {
        name: '',
        alias: '',
        school_email: '',
        school_phone: '',
        verification_document: null,
      },
      address: {
        country_id: null,
        region_id: null,
        city_id: null,
        town: null,
        box_address: null,
      },
      documentPreview: null,
    }
  },
  computed: {
    darkMode() {
      return skin.value === 'dark'
    },
    countries() {
      return this.$store.getters['auth/countries']
    },
    regions() {
      return this.$store.getters['auth/regions']
    },
    cities() {
      return this.$store.getters['auth/cities']
    },
    filteredRegions() {
      return this.regions.filter(r => +r.country_id === +this.address.country_id)
    },
    filteredCities() {
      return this.cities.filter(c => +c.region_id === +this.address.region_id)
    },
  },
  mounted() {
    this.fetchAddresses()
    window.onbeforeunload = e => {
      this.registerAttempt('closed')
      e.preventDefault()
      // eslint-disable-next-line no-return-assign
      return e.returnValue = 'Are you sure you want to leave this page?'
    }
  },
  methods: {
    registerAttempt(type) {
      this.$apollo.mutate({
        mutation: REGISTER_SIGNUP_ATTEMPT_M,
        variables: {
          input: {
            type,
            data: JSON.stringify({ admin: this.admin, school: this.school, address: this.address }),
          },
        },
      })
    },
    handleUpload(uploaded) {
      this.school.verification_document = uploaded.file
      this.documentPreview = uploaded.dataUrl
    },
    fetchAddresses() {
      this.$apollo.query({ query: FETCH_ADDRESSES_Q })
        .then(res => {
          this.$store.commit('auth/setAddresses', res.data)
        })
    },
    register() {
      // if (this.school.verification_document === null) {
      //   this.showError('Please upload a document under school information')
      // } else {
      this.error = {}
      this.submitting = true
      this.$apollo.mutate({
        mutation: REGISTER_M,
        variables: {
          user: this.admin,
          school: this.school,
          address: this.address,
        },
      })
        .then(() => {
          this.submitting = false
          this.showSuccess('Account created successfully, please login.')
          this.$router.push({ name: 'login' })
        })
        .catch(error => {
          this.showError('Something went wrong')
          this.submitting = false
          this.error = error
        })
    },
  },
  // },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
